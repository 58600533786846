import React, { useRef, useState } from "react";
import bg from "../Assets/bg.jpg";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";

const errorStyle = {
  color: "red",
  margin: "0",
  textAlign: "left",
  border: "red",
  marginLeft: "10px",
};

const Contact = () => {
  const form = useRef();

  const [mailerState, setMailerState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  });

  const handleStateChange = (e) => {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const submitEmail = async (e) => {
    e.preventDefault();
    console.log(mailerState);

    const response = await fetch("https://dtouch.in/elicit-contact.php", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(mailerState),
    })
      .then((res) => res.json())
      .then(() => {
        setMailerState({
          firstName: "",
          lastName: "",
          phone: "",
          company: "",
          email: "",
          message: "",
        });
      });
    console.log(response);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getValues = (value, e) => {
    e.preventDefault();
    console.log(value);
    submitEmail(e);
  };

  return (
    <div>
      <Box
        position="relative"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: "50px",
        }}
      >
        <img src={bg} width="100%" height="500px" alt="" />
        <Box
          position="absolute"
          sx={{
            display: "flex",
            width: "50%",
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0,0,0,0.6)",
            height: "200px",
            borderRadius: "50px",
            "@media (max-width: 1100px)": { width: "80%" },
            "@media (max-width: 500px)": { width: "100%" },
          }}
        ></Box>
        <Box
          position="absolute"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "600", color: "white" }}>
            CONTACT US
          </Typography>
        </Box>
      </Box>
      <Grid
        container
        spacing={5}
        sx={{
          padding: "50px 150px",
          "@media (max-width: 491px)": { p: "10px" },
        }}
      >
        <Grid
          item
          lg={6}
          sx={{ display: "flex", flexDirection: "column", gap: "30px" }}
        >
          <Typography
            variant="p"
            sx={{
              display: "flex",
              width: "60%",
              fontSize: "35px",
              fontWeight: "400",
            }}
          >
            Leave us a message.
          </Typography>
          <hr style={{ width: "20%", border: "1px solid greenYellow" }} />
          <Box sx={{ display: "flex", gap: "15px", flexDirection: "column" }}>
            <Typography variant="p" sx={{ fontWeight: "500" }}>
              Phone
            </Typography>
            <Typography variant="p">+91 74492-22939</Typography>
            <Typography variant="p">+91 99448-78752</Typography>
            <Typography variant="p">+91 70942-22939</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "15px", flexDirection: "column" }}>
            <Typography variant="p" sx={{ fontWeight: "500" }}>
              Email
            </Typography>
            <Typography variant="p">info@elicitgreen.com</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "15px", flexDirection: "column" }}>
            <Typography variant="p" sx={{ fontWeight: "500" }}>
              Address
            </Typography>
            <Typography variant="p" sx={{ display: "flex", width: "70%" }}>
              NO: 54, VSSM COMPLEX, 3rd FLOOR, WB ROAD, TRICHY - 620008.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "15px", flexDirection: "column" }}>
            <Typography variant="p">Mon-Fri | 9am – 5pm</Typography>
            <Typography variant="p">Sat-Sun | 9am – 2pm</Typography>
          </Box>
        </Grid>
        <Grid
          item
          lg={6}
          // sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <form
            ref={form}
            onSubmit={handleSubmit(getValues)}
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
              <Typography variant="p" sx={{ fontWeight: "500" }}>
                Name
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "space-between",
                }}
              >
                <Box width="100%">
                  <TextField
                    {...register("firstName", { required: true })}
                    id="outlined-basic"
                    label="First Name"
                    name="firstName"
                    value={mailerState.firstName}
                    onChange={handleStateChange}
                    variant="outlined"
                    fullWidth
                  />
                  {errors.firstName && (
                    <p style={errorStyle}>Enter First Name</p>
                  )}
                </Box>
                <Box width="100%">
                  <TextField
                    {...register("lastName", { required: true })}
                    id="outlined-basic"
                    label="Last Name"
                    name="lastName"
                    value={mailerState.lastName}
                    onChange={handleStateChange}
                    variant="outlined"
                    fullWidth
                  />
                  {errors.lastName && <p style={errorStyle}>Enter Last Name</p>}
                </Box>
              </Box>
            </Box>
            {/* <Box sx={{ display: 'flex', gap: '20px' }}> */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
              <Typography variant="p" sx={{ fontWeight: "500" }}>
                Phone
              </Typography>
              <TextField
                {...register("phone", {
                  required: "Enter Phone Number",
                  minLength: {
                    value: 10,
                    message: "Number must contain 10 digits",
                  },
                })}
                id="outlined-basic"
                label="Phone"
                name="phone"
                value={mailerState.phone}
                onChange={handleStateChange}
                variant="outlined"
                fullWidth
              />
              {errors.phone && <p style={errorStyle}>{errors.phone.message}</p>}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
              <Typography variant="p" sx={{ fontWeight: "500" }}>
                Email
              </Typography>
              <TextField
                {...register("email", {
                  required: true,
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                })}
                id="outlined-basic"
                label="Email"
                name="email"
                value={mailerState.email}
                onChange={handleStateChange}
                variant="outlined"
                fullWidth
              />
              {errors.email && (
                <p style={errorStyle}>Please enter a valid email address</p>
              )}
            </Box>
            {/* </Box> */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
              <Typography variant="p" sx={{ fontWeight: "500" }}>
                Company
              </Typography>
              <TextField
                {...register("company", { required: true })}
                id="outlined-basic"
                label="Company"
                name="company"
                value={mailerState.company}
                onChange={handleStateChange}
                variant="outlined"
                fullWidth
              />
              {errors.company && <p style={errorStyle}>Enter Company Name</p>}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
              <Typography variant="p" sx={{ fontWeight: "500" }}>
                Message
              </Typography>
              <TextField
                {...register("message", { required: true })}
                id="outlined-basic"
                label="Message"
                name="message"
                value={mailerState.message}
                onChange={handleStateChange}
                variant="outlined"
                multiline
                rows={4}
                fullWidth
              />
              {errors.message && <p style={errorStyle}>Enter a Message</p>}
            </Box>
            <Button
              type="submit"
              variant="contained"
              sx={{
                width: "fit-content",
                p: "10px 30px",
                background:
                  "linear-gradient(90deg, rgba(0,10,135,1) 0%, rgba(70,69,150,1) 100%)",
              }}
            >
              SUBMIT
            </Button>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default Contact;
