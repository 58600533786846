import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import SwiperCore from "swiper";
import { Autoplay } from "swiper/modules";
import ImageSlider from "../Component/ImageSlider";
import "./Card.css";
import { keyframes } from "@emotion/react";

SwiperCore.use([Autoplay]);

const bashing = keyframes`
0% {
  transform: scale(0);
  opacity: 0;
}
30% {
  transform: scale(1.3);
  opacity: 1;
}
50% {
  transform: scale(0.9);
}
70% {
  transform: scale(1.1);
}
100% {
  transform: scale(1);
}
`;

// Define keyframe animation for rotating images
// const rotating = keyframes`
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// `;

// Define keyframe animation
const slideDown = keyframes`
0% {
opacity: 0;
transform: translateY(-20px);
}
100% {
opacity: 1;
transform: translateY(0);
}
`;

// Keyframe animation for card appearance
const cardFadeIn = keyframes`
0% {
  opacity: 0;
  transform: translateY(20px);
}
100% {
  opacity: 1;
  transform: translateY(0);
}
`;

const ToggleCard = ({
  id,
  title,
  imageUrl,
  contentTitle,
  isExpanded,
  handleImageClick,
  brands,
}) => {
  const [isVisible, setIsVisible] = useState(false); // State to track visibility
  const cardRef = useRef(null); // Ref to attach to the card

  // Use IntersectionObserver to trigger animation on scroll
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(cardRef.current); // Stop observing once it's visible
        }
      },
      { threshold: 0.2 } // Trigger when 20% of the card is visible
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  return (
    <Card
      ref={cardRef}
      sx={{
        maxWidth: "400px",
        transition: "transform 0.3s ease-in-out",
        animation: isVisible ? `${cardFadeIn} 0.8s ease-in-out` : "none",
        "&:hover": {
          transform: "scale(1.05)",
          boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      {/* Card Header */}
      <CardHeader
        title={
          <Typography
            variant="p"
            sx={{
              animation: isVisible ? `${bashing} 1s ease-in-out` : "none",
              display: "inline-block",
              fontSize: 'larger',
              '&:hover': {
                animation: `${bashing} 0.8s ease-in-out infinite`, // Apply bashing animation repeatedly on hover
              },
            }}
          >
            {title}
          </Typography>
        }
        sx={{
          textAlign: "center",
          background: "linear-gradient(45deg, #32CD32 30%, #1E90FF 90%)",
          color: "#ebd6f5",
          fontWeight: "900",
          // animation: isVisible ? `${slideDown} 1s ease-in-out` : "none",
        }}
      />

      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        loop={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }} // Autoplay with a 3-second delay
        speed={2000}
      >
        {imageUrl.map((image, index) => (
          <SwiperSlide key={index}>
            {/* <Box
              sx={{
                // animation: `${rotating} 4s linear infinite`,
                width: "100%",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            > */}
            <img
              src={image}
              alt={`Slide ${index}`}
              style={{
                width: "100%",
                height: "200px",
                cursor: "pointer",
              }}
              onClick={() => handleImageClick(id)}
            />
            {/* </Box> */}
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Conditional rendering of the Card Content for the clicked card */}
      {isExpanded && (
        <CardContent sx={{ fontSize: "bold", textAlign: "justify" }}>
          <Typography variant="p" sx={{ fontWeight: "600", fontSize: "21px" }}>
            {contentTitle}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", mt: "20px" }}>
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                alignItems: "flex-start",
                color: "#666666",
                fontWeight: "600",
              }}
            >
              {brands.map((brand, index) => (
                <li
                  style={{
                    marginLeft: "15px",
                    overflow: "visible",
                    display: "list-item",
                    textTransform: "uppercase",
                  }}
                  key={index}
                >
                  {brand}
                </li>
              ))}
            </ul>
          </Box>
        </CardContent>
      )}
    </Card>
  );
};

export default ToggleCard;
