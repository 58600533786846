import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Home/Home';
import Header from './Component/Header';
// import AboutUs from './About Us/AboutUs';
import Footer from './Component/Footer';
import Products from './Products/Products';
import Contact from './Contact Us/Contact';
import Gallery from './Gallery/Gallery';
import Utility from './Service/Utility/Utility';
import OnGrid from './Service/RoofTop/OnGrid';
import OffGrid from './Service/RoofTop/OffGrid';
import Blog from './Blog/Blog';
import Blog1 from './Blog/Blog1';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Header />
      <Routes>
        <Route exact path='/' element={<Home/>} />
      </Routes>
      {/* <Routes>
        <Route exact path='/aboutus' element={<AboutUs/>} />
      </Routes> */}
      <Routes>
        <Route exact path='/utility' element={<Utility/>} />
      </Routes>
      <Routes>
        <Route exact path='/onGrid' element={<OnGrid/>} />
      </Routes>
      <Routes>
        <Route exact path='/offGrid' element={<OffGrid/>} />
      </Routes>
      <Routes>
        <Route exact path='/products' element={<Products/>} />
      </Routes>
      <Routes>
        <Route exact path='/gallery' element={<Gallery/>} />
      </Routes>
      <Routes>
        <Route exact path='/blog' element={<Blog/>} />
      </Routes>
      <Routes>
        <Route exact path='/blog1' element={<Blog1/>} />
      </Routes>
      <Routes>
        <Route exact path='/contactUs' element={<Contact/>} />
      </Routes>
      <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
