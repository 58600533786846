import React from "react";
import { Box, Grid, Typography, Card, CardContent } from "@mui/material";
import img1 from "../../Assets/Services/RoofTop/OnGrid/img1.jpeg";
import img2 from "../../Assets/Elicit/RoofTop1.jpeg";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import MicrowaveIcon from "@mui/icons-material/Microwave";
import GasMeterIcon from "@mui/icons-material/GasMeter";

const OnGrid = () => {
  return (
    <div>
      <Box
        position="relative"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <img src={img1} width="100%" height="500px" alt="" />
        <Box
          position="absolute"
          sx={{
            display: "flex",
            width: "50%",
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0,0,0,0.6)",
            height: "200px",
            borderRadius: "50px",
            "@media (max-width: 1100px)": { width: "80%" },
            "@media (max-width: 500px)": { width: "100%" },
          }}
        ></Box>
        <Box
          position="absolute"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "600", color: "white" }}>
            ROOFTOP ON-GRID SERVICES
          </Typography>
          
        </Box>
      </Box>
      <Grid container spacing={2} mt="50px" p="0 70px">
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="p"
            sx={{ fontSize: "32px", fontWeight: "600", color: "#00ff2a" }}
          >
            On-Grid Rooftop Solar Systems
          </Typography>
          <br />
          <Typography
            variant="p"
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "27px",
              letterSpacing: "1px",
            }}
          >
            For residential and businesses looking to seamlessly integrate solar
            power into the existing electrical grid, an on-grid solar system is
            the optimal choice. On-grid solar systems are directly connected to
            the utility grid, allowing you to draw supplementary power from the
            grid when needed and feed any excess solar-generated electricity
            back into the grid.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={img2} width="100%" height="100%" alt="" style={{ borderRadius: '20px' }} />
        </Grid>
      </Grid>
      <Box mt="40px">
        <Typography
          variant="p"
          sx={{
            fontSize: "30px",
            fontWeight: "600",
            display: "flex",
            justifyContent: "center",
          }}
        >
          How Does it Work?
        </Typography>
        <Grid container spacing={2} mt="40px" p="0 70px">
          <Grid item sm={12} md={4}>
            <Card
              sx={{
                bgcolor: "#343536",
                height: "280px",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                },
                "@media (max-width: 500px)": { height: "300px" },
                "@media (min-width: 900px)": { height: "350px" },
                "@media (min-width: 1210px)": { height: "280px" },
              }}
            >
              <CardContent
                sx={{
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <SolarPowerIcon
                  sx={{
                    fontSize: "70px",
                    backgroundColor: "brown",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px",
                    mb: "20px",
                  }}
                />
                <Typography variant="p" sx={{ fontWeight: "600" }}>
                  SOLAR PANELS
                </Typography>
                <br />
                <Typography
                  variant="p"
                  sx={{ lineHeight: "25px", letterSpacing: "1px" }}
                >
                  Our high-efficiency solar panels capture sunlight and convert
                  it into direct current (DC) electricity.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} md={4}>
            <Card
              sx={{
                bgcolor: "#343536",
                height: "280px",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                },
                "@media (max-width: 500px)": { height: "300px" },
                "@media (min-width: 900px)": { height: "350px" },
                "@media (min-width: 1210px)": { height: "280px" },
              }}
            >
              <CardContent
                sx={{
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <MicrowaveIcon
                  sx={{
                    fontSize: "70px",
                    backgroundColor: "brown",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px",
                    mb: "20px",
                  }}
                />
                <Typography variant="p" sx={{ fontWeight: "600" }}>
                  INVERTER
                </Typography>
                <br />
                <Typography
                  variant="p"
                  sx={{ lineHeight: "25px", letterSpacing: "1px" }}
                >
                  The DC electricity is then converted into alternating current
                  (AC) electricity, which is compatible with your home's
                  electrical system.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} md={4}>
            <Card
              sx={{
                bgcolor: "#343536",
                height: "280px",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                },
                "@media (max-width: 500px)": { height: "300px" },
                "@media (min-width: 900px)": { height: "350px" },
                "@media (min-width: 1210px)": { height: "280px" },
              }}
            >
              <CardContent
                sx={{
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <GasMeterIcon
                  sx={{
                    fontSize: "70px",
                    backgroundColor: "brown",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px",
                    mb: "20px",
                  }}
                />
                <Typography variant="p" sx={{ fontWeight: "600" }}>
                  NET METERING
                </Typography>
                <br />
                <Typography
                  variant="p"
                  sx={{ lineHeight: "25px", letterSpacing: "1px" }}
                >
                  Excess electricity generated by your solar system can be sent
                  back to the grid for credit. This means you only pay for the
                  net amount of electricity you consume.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Box mt="80px" textAlign='center'>
          <Typography
            variant="p"
            // sx={{
            //   fontSize: "25px",
            //   fontWeight: "600",
            //   display: "flex",
            //   justifyContent: "center",
            //   textAlign: "center",
            //   backgroundColor: "#bfffbf",
            //   padding: "10px 20px",
            //   borderRadius: "10px",
              
            // }}
            sx={{
              display: "inline-grid",
              fontSize: "22px",
              fontWeight: "600",
              backgroundColor: "#40E0D0",
              padding: "10px 20px",
              borderRadius: "10px",
              textAlign: "center",
            }}
          >
            Key Benefits of On-Grid Rooftop Solar
          </Typography>
          <Grid container mt="40px">
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                bgcolor: "#f0df89",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "100px 70px",
                gap: "30px",
                height: "350px",
                "@media (max-width: 800px)": { padding: "10px" },
              }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "22px", fontWeight: "600" }}
              >
                Continuous Power Supply
              </Typography>
              <Typography
                variant="p"
                sx={{
                  textAlign: "center",
                  lineHeight: "30px",
                  "@media (max-width: 1000px)": { lineHeight: "20px" },
                }}
              >
                On-grid systems ensure you have access to reliable electricity
                at all times, even during grid outages, by drawing power from
                the utility grid when your solar panels are not producing
                enough.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                bgcolor: "#dbdbdb",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "100px 70px",
                gap: "30px",
                height: "350px",
                "@media (max-width: 800px)": { padding: "10px" },
              }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "22px", fontWeight: "600" }}
              >
                Net Metering Opportunities
              </Typography>
              <Typography
                variant="p"
                sx={{
                  textAlign: "center",
                  lineHeight: "30px",
                  "@media (max-width: 1000px)": { lineHeight: "20px" },
                }}
              >
                With net metering, any surplus solar energy your system
                generates can be credited back to your utility account,
                effectively reducing your overall electricity costs.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                bgcolor: "#dbdbdb",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "100px 70px",
                gap: "30px",
                height: "350px",
                "@media (max-width: 800px)": { padding: "10px" },
                "@media (max-width: 500px)": { bgcolor: "#f0df89" },
              }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "22px", fontWeight: "600" }}
              >
                No Battery Storage Required
              </Typography>
              <Typography
                variant="p"
                sx={{
                  textAlign: "center",
                  lineHeight: "30px",
                  "@media (max-width: 1000px)": { lineHeight: "20px" },
                }}
              >
                On-grid systems do not require battery storage, which can
                significantly lower the upfront installation costs.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                bgcolor: "#f0df89",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "100px 70px",
                gap: "30px",
                height: "350px",
                "@media (max-width: 800px)": { padding: "10px" },
                "@media (max-width: 500px)": { bgcolor: "#dbdbdb" },
              }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "22px", fontWeight: "600" }}
              >
                Seamless Integration
              </Typography>
              <Typography
                variant="p"
                sx={{
                  textAlign: "center",
                  lineHeight: "30px",
                  "@media (max-width: 1000px)": { lineHeight: "20px" },
                }}
              >
                On-grid solar systems are designed to easily integrate with your
                home or business's existing electrical infrastructure, making
                the installation process more straightforward.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default OnGrid;
