import React, { useRef } from "react";
// import Header from "../Component/Header";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import about2 from "../Assets/Elicit/Home1.jpeg";
import pie1 from "../Assets/pmc-removebg-preview.png";
import pie2 from "../Assets/civil-design-removebg-preview.png";
import service1 from "../Assets/Home/service1.jpg";
import service2 from "../Assets/Elicit/Home2.jpeg";
import service3 from "../Assets/Home/service3.jpg";
import "./Home.css";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import HandshakeIcon from "@mui/icons-material/Handshake";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import RoofingIcon from "@mui/icons-material/Roofing";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import EngineeringIcon from "@mui/icons-material/Engineering";
import advn1 from "../Assets/Elicit/Home3.jpeg";
import advn2 from "../Assets/AboutUs/advn2.jpg";
import EPC from "../Assets/Home/EPC.jpg";
import { Link } from "react-router-dom";
import bg from '../Assets/bg2.jpeg';

function Home() {
  const contentRef = useRef(null); // Reference for the content to scroll to

  const handleScrollToContent = () => {
    contentRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      {/* <Header /> */}
      <Grid container>
        <Grid item xs={12}>
          <div className="back-img"></div>
          {/* <img src={bg} height='60%' width='100%' alt="" /> */}
          <Box
            sx={{
              position: "absolute",
              top: "30%",
              width: "100%",
              textAlign: "center",
              color: "#fff",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "40px",
            }}
          >
            {/* <Box>
              <Typography
                variant="p"
                sx={{
                  fontSize: "20px",
                  background:
                    "linear-gradient(90deg, rgba(14,255,0,1) 0%, rgba(126,255,119,1) 100%)",
                  p: "5px 20px",
                  borderRadius: "20px",
                }}
              >
                Welcome
              </Typography>
            </Box> */}
            <Typography
              variant="h2"
              sx={{
                fontWeight: "600",
                width: "70%",
                "@media (max-width: 655px)": { fontSize: "35px" },
              }}
            >
              Sustainable, Reliable and Clean power to all its customers
            </Typography>
            {/* <Link to='/utility' style={{ textDecoration: 'none', color: 'inherit' }}> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "20px",
                cursor: "pointer",
              }}
              onClick={handleScrollToContent}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "25px",
                  fontWeight: "400",
                  background:
                    "linear-gradient(90deg, rgba(0,10,135,1) 0%, rgba(70,69,150,1) 100%)",
                  p: "10px 20px",
                  borderRadius: "30px",
                  "&:hover": {
                    background:
                      "linear-gradient(90deg, rgba(0,255,42,1) 17%, rgba(21,21,236,1) 100%)",
                  },
                }}
              >
                Read More
              </Typography>
              {/* <Typography
                variant="p"
                sx={{
                  fontSize: "25px",
                  fontWeight: "400",
                  background:
                    "linear-gradient(90deg, rgba(0,10,135,1) 0%, rgba(70,69,150,1) 100%);",
                  p: "10px 20px",
                  borderRadius: "30px",
                  "&:hover": {
                    background:
                      "linear-gradient(90deg, rgba(0,255,42,1) 17%, rgba(21,21,236,1) 100%)",
                  },
                }}
              >
                Contact Us
              </Typography> */}
            </Box>
            {/* </Link> */}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={5} sx={{ padding: "0 50px", mt: "0px" }}>
        <Grid item xs={12} lg={6} position="relative">
          <img
            src={about2}
            width="100%"
            height="100%"
            style={{ borderRadius: "50px" }}
            alt=""
          />
          <Box
            sx={{
              position: "absolute",
              bottom: "0",
              right: "0",
              width: "300px",
              height: "250px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              zIndex: "9999",
              background:
                "linear-gradient(90deg, rgba(150,115,255,1) 0%, rgba(50,255,60,1) 100%)",
              borderRadius: "20px",
              padding: "10px",
              "@media(max-width: 600px)": {
                width: "200px",
                height: "150px",
                bottom: "-25%",
                right: "-10%",
              },
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: "25px", fontWeight: "700", color: "#fff" }}
            >
              50+ MW(EPC)
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontSize: "25px",
                fontWeight: "700",
                textAlign: "center",
                color: "#fff",
              }}
            >
              100+ MW
              <br />
              (commissioning)
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ marginTop: "50px" }}>
          <Typography
            variant="h4"
            sx={{ pb: "20px", color: "#00ff2a", fontWeight: "500" }}
          >
            About Us
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: "600", pb: "20px" }}>
            What’s Our Resources Story
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontWeight: "400",
              fontSize: "18px",
              color: "#292929",
              textAlign: "justify",
              lineHeight: "34px",
            }}
          >
            {/* ELICIT The Green Energy company limited was founded in 2012. ELICIT
            THE GREEN ENERGY do business in Engineering, design,
            installation, Solar Farm, Solar Roof Top which is into power &
            electrical infrastructure since 2016. ELICIT THE GREEN
            ENERGY limited has been firmly established by our team of
            highly skilled professionals since 2022, committed to meet the
            need for an unparalleled quality of service provided to our clients.
            ELICIT THE GREEN ENERGY can assist with the planning, design, and
            development of your project and then provide “value added”
            construction and innovative solutions on schedule and within budget.
            As our business line has a satisfaction growth so we have separated
            into a group of department LIKE ELECTRICAL | CIVIL | STRACTURAL
            ENGINEERING | TRANSMISSION LINE in order to service functionally to
            our precious customer. */}
            ELICIT THE GREEN ENERGY Limited was founded in 2012. ELICIT THE
            GREEN ENERGY do business in Engineering, design, installation, Solar
            Farm, Solar Roof Top which is into power & electrical infrastructure
            since 2016. ELICIT THE GREEN ENERGY Limited has been firmly
            established by our team of highly skilled professionals since 2022,
            committed to meet the need for an unparalleled quality of service
            provided to our clients. ELICIT THE GREEN ENERGY can assist with the
            planning, design, and development of your project and then provide
            “value-added” construction and innovative solutions on schedule and
            within budget. As our business line has a satisfaction growth we
            have separated into a group of departments LIKE ELECTRICAL | CIVIL |
            STRUCTURAL ENGINEERING | TRANSMISSION LINE to service functionally
            to our precious customer.
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: "20px" }}>
        <Grid
          item
          xs={12}
          sx={{
            bgcolor: "#6d9a5e",
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            p: "50px 0",

            "@media (max-width: 426px)": {
              padding: "50px 10px",
              textAlign: "center",
            },
          }}
        >
          <Typography variant="h3">MISSION SOLAR</Typography>
          <Typography
            variant="p"
            sx={{ fontWeight: "500", fontSize: "18px", pt: "30px" }}
          >
            “The solar business aims to provide Sustainable, Reliable and Clean
            power to all its customers”.
          </Typography>
          <Typography variant="p" sx={{ fontWeight: "500", fontSize: "18px" }}>
            “Every kilowatt hour generated by solar energy means a reduction in
            pollutants, hence improving the overall ecology and moving towards a
            better future”
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            bgcolor: "#dbdbdb",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "100px 70px",
            gap: "30px",

            height: "350px",
            "@media (max-width: 426px)": { padding: "0 30px" },
            "@media (max-width: 800px)": { padding: "10px" },
          }}
        >
          <Typography
            variant="p"
            sx={{ fontSize: "22px", fontWeight: "600", textAlign: "center" }}
          >
            Engineering, Procurement, and Commissioning (EPC)
          </Typography>
          <Typography variant="p" sx={{ textAlign: "center" }}>
            Our comprehensive EPC services offer a one-stop solution for your
            solar energy project. From initial design and engineering to
            procurement of high-quality components and expert installation, we
            ensure seamless project execution. Our experienced team handles
            commissioning and testing, ensuring your solar system operates at
            peak efficiency.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            height: "350px",
            "@media (max-width: 599px)": { display: "none" },
          }}
        >
          <img src={service2} width="100%" height="100%" alt="" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            height: "350px",
            "@media (max-width: 599px)": { display: "none" },
          }}
        >
          <img src={service3} width="100%" height="100%" alt="" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            bgcolor: "#f0df89",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "100px 70px",
            gap: "30px",
            height: "350px",
            "@media (max-width: 426px)": { padding: "0 30px" },
            "@media (max-width: 800px)": { padding: "10px" },
          }}
        >
          <Typography
            variant="p"
            sx={{ fontSize: "22px", fontWeight: "600", textAlign: "center" }}
          >
            Powering Your Solar Project with Premium BOS Components
          </Typography>
          <Typography variant="p" sx={{ textAlign: "center" }}>
            At ELICIT GREEN ENERGY, we are committed to delivering comprehensive
            solar energy solutions. Our BOS (Balance of System) procurement
            services ensure you have access to top-quality components essential
            for maximizing your solar system's efficiency and performance.
          </Typography>
        </Grid>
        <Grid item xs={12} position="relative">
          {/* <img src={EPC} width="100%" height="1100px" alt="" /> */}
          <div className="content-wrap"></div>
          <Box
            sx={{
              position: "absolute",
              top: "5%",
              zIndex: "9999",
              // display: "flex",
              // flexDirection: "column",
              // alignItems: "center",
              // width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontWeight: "600",
                  textAlign: "center",
                  color: "#d1d1d1",
                  "@media (max-width: 600px)": {
                    fontSize: "35px",
                    color: "azure",
                  },
                }}
              >
                Engineering, Procurement, and Commissioning
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: "600",
                  color: "#d1d1d1",
                  "@media (max-width: 600px)": {
                    color: "azure",
                    fontSize: "35px",
                  },
                }}
              >
                (EPC)
              </Typography>
              <Typography
                variant="p"
                sx={{
                  textAlign: "center",
                  mt: "30px",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "30px",
                  color: "white",
                  "@media (max-width: 600px)": { color: "azure" },
                }}
              >
                Our EPC (Engineering, Procurement, and Construction) services
                ensure seamless execution of solar energy projects from start to
                finish. With a focus on delivering high-quality, cost-effective,
                and timely solutions, we manage every phase of your solar
                project to ensure its success.
              </Typography>
            </Box>
            <Grid
              container
              spacing={3}
              mt="50px"
              p="0 100px"
              sx={{ "@media(max-width: 600px)": { p: "10px" } }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                  padding: "20px",
                }}
              >
                <MilitaryTechIcon sx={{ fontSize: "75px", color: "white" }} />
                <Box>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "22px",
                      fontWeight: "500",
                      color: "#ffffff",
                      display: "flex",
                      textAlign: "center",
                    }}
                  >
                    Engineering Excellence
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="p"
                    sx={{
                      color: "#ffffff",
                      textAlign: "center",
                      display: "flex",
                      fontWeight: "300",
                      lineHeight: "25px",
                    }}
                  >
                    Our team of experienced engineers customizes solar solutions
                    tailored to meet your energy needs. From system design to
                    performance optimization, we ensure that every aspect of
                    your solar project is engineered for maximum efficiency and
                    reliability.
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                  padding: "20px",
                }}
              >
                <WorkspacePremiumIcon
                  sx={{ fontSize: "75px", color: "white" }}
                />
                <Box>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "22px",
                      fontWeight: "500",
                      color: "#ffffff",
                      display: "flex",
                      textAlign: "center",
                    }}
                  >
                    Procurement of Premium Components
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="p"
                    sx={{
                      color: "#ffffff",
                      textAlign: "center",
                      display: "flex",
                      fontWeight: "300",
                      lineHeight: "25px",
                    }}
                  >
                    We source only the highest-quality solar panels, inverters,
                    and components from trusted suppliers. Our procurement
                    process is driven by performance and durability, ensuring
                    that your solar system operates at peak efficiency for years
                    to come.
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                  padding: "20px",
                }}
              >
                <EngineeringIcon sx={{ fontSize: "75px", color: "white" }} />
                <Box>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "22px",
                      fontWeight: "500",
                      color: "#ffffff",
                      display: "flex",
                      textAlign: "center",
                    }}
                  >
                    Expert Construction & Installation
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="p"
                    sx={{
                      color: "#ffffff",
                      textAlign: "center",
                      display: "flex",
                      fontWeight: "300",
                      lineHeight: "25px",
                    }}
                  >
                    Our construction team brings expertise in installing solar
                    systems of all sizes—from small residential installations to
                    large commercial solar farms. We adhere to the highest
                    safety and quality standards, ensuring timely project
                    completion and a hassle-free installation experience.
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                  padding: "20px",
                }}
              >
                <ManageHistoryIcon sx={{ fontSize: "75px", color: "white" }} />
                <Box>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "22px",
                      fontWeight: "500",
                      color: "#ffffff",
                      display: "flex",
                      textAlign: "center",
                    }}
                  >
                    Project Management & Turnkey Solutions
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="p"
                    sx={{
                      color: "#ffffff",
                      textAlign: "center",
                      display: "flex",
                      fontWeight: "300",
                      lineHeight: "25px",
                    }}
                  >
                    We manage every step of the process, including site
                    assessment, permitting, and grid connection, to provide a
                    fully integrated, turnkey solar solution. Our goal is to
                    ensure that your solar project is delivered on time, within
                    budget, and to the highest standards of quality.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/* <Grid
          item
          xs={12}
          sm={6}
          sx={{
            bgcolor: "#dbdbdb",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 70px",
            gap: "30px",
            height: "350px",
            "@media (max-width: 426px)": { padding: "0 30px" },
            "@media (max-width: 800px)": { padding: "10px" },
          }}
        >
          <Typography
            variant="p"
            sx={{ fontSize: "22px", fontWeight: "600", textAlign: "center" }}
          >
            Powering Innovation
          </Typography>
          <Typography variant="p" sx={{ textAlign: "center" }}>
            Emphasize your company's commitment to cutting-edge technology and
            engineering excellence in solar panel design and manufacturing.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            height: "350px",
            "@media (max-width: 599px)": { display: "none" },
          }}
        >
          <img src={service1} width="100%" height="100%" alt="" />
        </Grid> */}

        <Grid container mt="30px" ref={contentRef}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "15px",
              mb: "30px",
            }}
          >
            <Typography
              variant="p"
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                backgroundColor: "#9eff9e",
                padding: "10px 20px",
                borderRadius: "20px",
              }}
            >
              OUR SERVICES
            </Typography>
            <Typography
              variant="p"
              sx={{ fontSize: "26px", fontWeight: "700" }}
            >
              What We Offer
            </Typography>
          </Grid>
          <Grid container spacing={2} sx={{ p: "0 50px 20px 50px" }}>
            <Grid item xs={12} md={6} lg={3}>
              <Card
                sx={{
                  bgcolor: "#343536",
                  height: "350px",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <CardContent
                  sx={{
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    height: "300px",
                  }}
                >
                  <Diversity1Icon
                    sx={{
                      fontSize: "70px",
                      backgroundColor: "brown",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "5px",
                      mb: "20px",
                    }}
                  />
                  <Typography variant="p" sx={{ fontWeight: "600" }}>
                    UTILITY SERVICES
                  </Typography>
                  <br />
                  <Typography
                    variant="p"
                    sx={{ lineHeight: "25px", letterSpacing: "1px", textAlign: 'justify' }}
                  >
                    Our team handles all necessary utility connections and
                    permits, ensuring a smooth and efficient installation
                    process.
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "flex-end" }}>
                  <a href="/utility">
                    <Button>Learn More</Button>
                  </a>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card
                sx={{
                  bgcolor: "#343536",
                  height: "350px",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <CardContent
                  sx={{
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    height: "300px",
                  }}
                >
                  <RoofingIcon
                    sx={{
                      fontSize: "70px",
                      backgroundColor: "brown",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "5px",
                      mb: "20px",
                    }}
                  />
                  <Typography variant="p" sx={{ fontWeight: "600" }}>
                    ROOFTOP SERVICES
                  </Typography>
                  <br />
                  <Typography
                    variant="p"
                    sx={{ lineHeight: "25px", letterSpacing: "1px" }}
                  >
                    We offer comprehensive rooftop solar solutions, including
                    system design, installation, and maintenance, tailored to
                    your specific needs.
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "flex-end" }}>
                  <a href="/onGrid">
                    <Button>Learn More</Button>
                  </a>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card
                sx={{
                  bgcolor: "#343536",
                  height: "350px",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <CardContent
                  sx={{
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    height: "300px",
                  }}
                >
                  <EnergySavingsLeafIcon
                    sx={{
                      fontSize: "70px",
                      backgroundColor: "brown",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "5px",
                      mb: "20px",
                    }}
                  />
                  <Typography variant="p" sx={{ fontWeight: "600" }}>
                    ON-GRID SERVICES
                  </Typography>
                  <br />
                  <Typography
                    variant="p"
                    sx={{ lineHeight: "25px", letterSpacing: "1px" }}
                  >
                    Our on-grid solar systems help you reduce electricity costs
                    and contribute to a cleaner environment by exporting excess
                    power back to the grid.
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "flex-end" }}>
                  <a href="/onGrid">
                    <Button>Learn More</Button>
                  </a>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card
                sx={{
                  bgcolor: "#343536",
                  height: "350px",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <CardContent
                  sx={{
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    height: "300px",
                  }}
                >
                  <SolarPowerIcon
                    sx={{
                      fontSize: "70px",
                      backgroundColor: "brown",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "5px",
                      mb: "20px",
                    }}
                  />
                  <Typography variant="p" sx={{ fontWeight: "600" }}>
                    OFF-GRID SERVICES
                  </Typography>
                  <br />
                  <Typography
                    variant="p"
                    sx={{ lineHeight: "25px", letterSpacing: "1px" }}
                  >
                    Our off-grid solar systems provide reliable power solutions
                    for remote locations and areas with frequent power outages.
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "flex-end" }}>
                  <a href="/offGrid">
                    <Button>Learn More</Button>
                  </a>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            bgcolor: "#f0df89",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "100px 70px",
            gap: "30px",
            height: "350px",
            "@media (max-width: 800px)": { padding: "10px" },
          }}
        >
          <Typography variant="p" sx={{ fontSize: "22px", fontWeight: "600" }}>
            Energy Independence
          </Typography>
          <Typography
            variant="p"
            sx={{
              textAlign: "center",
              lineHeight: "30px",
              "@media (max-width: 1000px)": { lineHeight: "20px" },
            }}
          >
            By harnessing the power of the sun, you can reduce or eliminate your
            dependence on the traditional electricity grid. Solar panels
            generate clean energy on-site, providing a reliable and sustainable
            power source. This independence shields you from fluctuating
            electricity rates and potential power outages, ensuring a consistent
            energy supply for your home or business.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ height: "350px" }}>
          <img src={advn1} width="100%" height="100%" alt="" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            height: "350px",
            "@media (max-width: 599px)": { display: "none" },
          }}
        >
          <img src={advn2} width="100%" height="100%" alt="" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            bgcolor: "#dbdbdb",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "100px 70px",
            gap: "30px",
            height: "350px",
            "@media (max-width: 800px)": { padding: "10px" },
          }}
        >
          <Typography variant="p" sx={{ fontSize: "22px", fontWeight: "600" }}>
            Low Maintenance
          </Typography>
          <Typography
            variant="p"
            sx={{
              textAlign: "center",
              lineHeight: "30px",
              "@media (max-width: 1000px)": { lineHeight: "20px" },
            }}
          >
            Solar panels are renowned for their low maintenance requirements.
            Unlike traditional power sources, they don't have moving parts that
            frequently break down or require frequent replacements. With minimal
            upkeep, such as periodic cleaning to remove debris, solar panels can
            operate efficiently for decades. This translates into significant
            cost savings and hassle-free ownership.
          </Typography>
        </Grid>
        <Grid item xs={12} position="relative">
          <div className="cover-img"></div>
          <Box
            sx={{
              position: "absolute",
              top: "10%",
              zIndex: "9999",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontWeight: "600",
                textAlign: "center",
                "@media (max-width: 600px)": {
                  fontSize: "35px",
                  color: "azure",
                },
              }}
            >
              Installation – Erection – Testing
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontWeight: "600",
                "@media (max-width: 600px)": {
                  color: "azure",
                  fontSize: "35px",
                },
              }}
            >
              (Commissioning)
            </Typography>
            <Typography
              variant="p"
              sx={{
                textAlign: "center",
                mt: "30px",
                fontSize: "18px",
                fontWeight: "500",
                lineHeight: "30px",
                "@media (max-width: 600px)": { color: "azure" },
              }}
            >
              We are the service provider to all kind of customers from 1 KWp to
              100 MWp, The ACDC electrical team, civil Team & Structural
              professionals will be completed the projects in time being as per
              the given drawings. Our Authorized Testing & quality Team are
              honest to certify the work by using the valid instruments with the
              valid certificate. Our One of the ESE certified A grade vendor
              team are excellence in HT (11 KV | 33 KV | 110 KV) Feeder | Yard |
              Bay & Transmission line work.
            </Typography>
          </Box>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <img src={pie1} width="100%" height="auto" alt="" />
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={pie2} width="100%" height="auto" alt="" />
        </Grid> */}
      </Grid>

      <Grid
        container
        spacing={3}
        sx={{
          mt: "30px",
          p: "0 100px",
          justifyContent: "center",
          "@media (max-width: 1024px)": { p: "0 50px" },
          "@media (max-width: 500px)": { p: "0 10px" },
        }}
      >
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", mb: "30px" }}
        >
          <Typography
            variant="p"
            sx={{
              textAlign: "center",
              fontSize: "28px",
              fontWeight: "700",
              borderBottom: "3px solid #05bf02",
              pb: "10px",
            }}
          >
            Why Choose <span style={{ color: "#05bf02" }}>Us?</span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              borderRadius: "10px",
              p: "10px 20px",
              width: "100%",
              height: "auto",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#d0d0e7",
                borderRadius: "50%",
                p: "10px",
                display: "flex",
              }}
            >
              <EmojiEventsIcon sx={{ color: "#0c920c", fontSize: "35px" }} />
            </Box>
            <Typography
              variant="p"
              sx={{ fontWeight: "500", textAlign: "center" }}
            >
              Proven Track Record, Megawatt-Sized Success
            </Typography>
            <Typography
              variant="p"
              sx={{ textAlign: "center", p: "0 30px", color: "#6e6e6e" }}
            >
              With a robust portfolio exceeding
              <span
                style={{
                  backgroundColor: "#bff1bf",
                  borderRadius: "10px",
                }}
              >
                {" "}
                50+ MW(EPC) and 100+ MW(commissioning)
              </span>
              , Our company boasts a proven track record in delivering
              successful solar energy projects. Our extensive experience ensures
              efficient project execution, optimal system performance, and
              maximum return on investment for our clients.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              borderRadius: "10px",
              p: "10px 20px",
              width: "100%",
              height: "auto",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#d0d0e7",
                borderRadius: "50%",
                p: "10px",
                display: "flex",
              }}
            >
              <Diversity2Icon sx={{ color: "#0c920c", fontSize: "35px" }} />
            </Box>
            <Typography
              variant="p"
              sx={{ fontWeight: "500", textAlign: "center" }}
            >
              Comprehensive In-House Expertise for Optimal Solutions
            </Typography>
            <Typography
              variant="p"
              sx={{ textAlign: "center", p: "0 30px", color: "#6e6e6e" }}
            >
              Our in-house design and engineering teams, coupled with
              state-of-the-art infrastructure, empower us to deliver tailored
              solar solutions. Benefit from our deep technical expertise as we
              optimize system design, streamline project execution, and provide
              ongoing support to maximize your solar investment.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              borderRadius: "10px",
              p: "10px 20px",
              width: "100%",
              height: "auto",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#d0d0e7",
                borderRadius: "50%",
                p: "10px",
                display: "flex",
              }}
            >
              <ManageHistoryIcon sx={{ color: "#0c920c", fontSize: "35px" }} />
            </Box>
            <Typography
              variant="p"
              sx={{ fontWeight: "500", textAlign: "center" }}
            >
              Seamless Project Execution with Single-Point Accountability
            </Typography>
            <Typography
              variant="p"
              sx={{ textAlign: "center", p: "0 30px", color: "#6e6e6e" }}
            >
              Experience hassle-free project management with our one-point
              contract responsibility. From design to installation and
              commissioning, we handle every aspect of your solar project,
              ensuring efficient coordination, timely completion, and superior
              results.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              borderRadius: "10px",
              p: "10px 20px",
              width: "100%",
              height: "auto",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#d0d0e7",
                borderRadius: "50%",
                p: "10px",
                display: "flex",
              }}
            >
              <EmojiEmotionsIcon sx={{ color: "#0c920c", fontSize: "35px" }} />
            </Box>
            <Typography
              variant="p"
              sx={{ fontWeight: "500", textAlign: "center" }}
            >
              Navigating Permitting Challenges with Ease
            </Typography>
            <Typography
              variant="p"
              sx={{ textAlign: "center", p: "0 30px", color: "#6e6e6e" }}
            >
              Our dedicated liaisoning team possesses in-depth knowledge of the
              complex permitting landscape across India. We streamline the
              permitting process, ensuring timely approvals and minimizing
              project delays. Our expertise guarantees smooth project execution
              and adherence to all regulatory requirements.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              borderRadius: "10px",
              p: "10px 20px",
              width: "100%",
              height: "auto",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#d0d0e7",
                borderRadius: "50%",
                p: "10px",
                display: "flex",
              }}
            >
              <HandshakeIcon sx={{ color: "#0c920c", fontSize: "35px" }} />
            </Box>
            <Typography
              variant="p"
              sx={{ fontWeight: "500", textAlign: "center" }}
            >
              Strong Partnerships for Unparalleled Solutions
            </Typography>
            <Typography
              variant="p"
              sx={{ textAlign: "center", p: "0 30px", color: "#6e6e6e" }}
            >
              Our strategic collaborations with industry leaders enhance our
              technical capabilities and expand our solution offerings. This,
              combined with our extensive know-how, positions us as the
              preferred power solutions provider for our clients. Experience the
              benefits of our strong partnerships and cutting-edge technology.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
