import React from "react";
import "./Gallery.css";
import g1 from "../Assets/Gallery/g1.jpeg";
import g2 from "../Assets/Gallery/g2.jpeg";
import g3 from "../Assets/Gallery/g3.jpeg";
import g4 from "../Assets/Gallery/g4.jpeg";
import g5 from "../Assets/Gallery/g5.jpeg";
import g6 from "../Assets/Gallery/g6.jpeg";
import g7 from "../Assets/Gallery/g7.jpeg";
import g8 from "../Assets/Gallery/g8.jpeg";
import g9 from "../Assets/Gallery/g9.jpeg";
import g10 from "../Assets/Gallery/g10.jpeg";
import g11 from "../Assets/Gallery/g11.jpeg";
import g12 from "../Assets/Gallery/g12.jpeg";
import g13 from "../Assets/Gallery/g13.jpeg";
import g14 from "../Assets/Gallery/g14.jpeg";
import g15 from "../Assets/Gallery/g15.jpeg";
import g16 from "../Assets/Gallery/g16.jpeg";
import g17 from "../Assets/Gallery/g17.jpeg";
import g18 from "../Assets/Gallery/g18.jpeg";
import g19 from "../Assets/Gallery/g19.jpeg";
import g20 from "../Assets/Gallery/g20.jpeg";
import g21 from "../Assets/Gallery/g21.jpeg";
import g22 from "../Assets/Gallery/g22.jpeg";
import g23 from "../Assets/Gallery/g23.jpeg";
import g24 from "../Assets/Gallery/g24.jpeg";
import g25 from "../Assets/Gallery/g25.jpeg";
import g26 from "../Assets/Gallery/g26.jpeg";
import g27 from "../Assets/Gallery/g27.jpeg";
import g28 from "../Assets/Gallery/g28.jpeg";
import g29 from "../Assets/Gallery/g29.jpeg";
import g30 from "../Assets/Gallery/g30.jpeg";
import g31 from "../Assets/Gallery/g31.jpeg";
import g32 from "../Assets/Gallery/g32.jpeg";
import g33 from "../Assets/Gallery/g33.jpeg";
import g34 from "../Assets/Gallery/g34.jpeg";
import g35 from "../Assets/Gallery/g35.jpeg";
import g36 from "../Assets/Gallery/g36.jpeg";
import g37 from "../Assets/Gallery/g37.jpeg";
import g38 from "../Assets/Gallery/g38.jpeg";
import g39 from "../Assets/Gallery/g39.jpeg";
import g40 from "../Assets/Gallery/g40.jpeg";
import g41 from "../Assets/Gallery/g41.jpeg";
import g42 from "../Assets/Gallery/g42.jpeg";
import g43 from "../Assets/Gallery/g43.jpeg";
import g44 from "../Assets/Gallery/g44.jpeg";
import g45 from "../Assets/Gallery/g45.jpeg";
import g46 from "../Assets/Gallery/g46.jpeg";
import img3 from "../Assets/Services/Utility/img3.jpg";
import { Box, Grid, Typography } from "@mui/material";

const Gallery = () => {
  let data = [
    {
      id: 1,
      imgSrc: g1,
    },
    {
      id: 2,
      imgSrc: g2,
    },
    {
      id: 3,
      imgSrc: g3,
    },
    {
      id: 4,
      imgSrc: g4,
    },
    {
      id: 5,
      imgSrc: g5,
    },
    {
      id: 6,
      imgSrc: g6,
    },
    {
      id: 7,
      imgSrc: g7,
    },
    {
      id: 8,
      imgSrc: g8,
    },
    {
      id: 9,
      imgSrc: g9,
    },
    {
      id: 10,
      imgSrc: g10,
    },
    {
      id: 11,
      imgSrc: g11,
    },
    {
      id: 12,
      imgSrc: g12,
    },
    {
      id: 13,
      imgSrc: g13,
    },
    {
      id: 14,
      imgSrc: g14,
    },
    {
      id: 15,
      imgSrc: g15,
    },
    {
      id: 16,
      imgSrc: g16,
    },
    {
      id: 17,
      imgSrc: g17,
    },
    {
      id: 18,
      imgSrc: g18,
    },
    {
      id: 19,
      imgSrc: g19,
    },
    {
      id: 20,
      imgSrc: g20,
    },
    {
      id: 21,
      imgSrc: g21,
    },
    {
      id: 22,
      imgSrc: g22,
    },
    {
      id: 23,
      imgSrc: g23,
    },
    {
      id: 24,
      imgSrc: g24,
    },
    {
      id: 25,
      imgSrc: g25,
    },
    {
      id: 26,
      imgSrc: g26,
    },
    {
      id: 27,
      imgSrc: g27,
    },
    {
      id: 28,
      imgSrc: g28,
    },
    {
      id: 29,
      imgSrc: g29,
    },
    {
      id: 30,
      imgSrc: g30,
    },
    {
      id: 31,
      imgSrc: g31,
    },
    {
      id: 32,
      imgSrc: g32,
    },
    {
      id: 33,
      imgSrc: g33,
    },
    {
      id: 34,
      imgSrc: g34,
    },
    {
      id: 35,
      imgSrc: g35,
    },
    {
      id: 36,
      imgSrc: g36,
    },
    {
      id: 37,
      imgSrc: g37,
    },
    {
      id: 38,
      imgSrc: g38,
    },
    {
      id: 39,
      imgSrc: g39,
    },
    {
      id: 40,
      imgSrc: g40,
    },
    {
      id: 41,
      imgSrc: g41,
    },
    {
      id: 42,
      imgSrc: g42,
    },
    {
      id: 43,
      imgSrc: g43,
    },
    {
      id: 44,
      imgSrc: g44,
    },
    {
      id: 45,
      imgSrc: g45,
    },
    {
      id: 46,
      imgSrc: g46,
    },
  ];
  

  return (
    <>
      <Box
        position="relative"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: "50px",
        }}
      >
        <img src='https://cdn.pixabay.com/photo/2020/10/03/09/05/solar-energy-5622969_640.jpg' width="100%" height="500px" alt="" />
        <Box
          position="absolute"
          sx={{
            display: "flex",
            width: "50%",
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0,0,0,0.6)",
            height: "200px",
            borderRadius: "50px",
            "@media (max-width: 1100px)": { width: "80%" },
            "@media (max-width: 500px)": { width: "100%" },
          }}
        ></Box>
        <Box
          position="absolute"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "600", color: "white" }}>
            GALLERY
          </Typography>
        </Box>
      </Box>
      {/* <div className={model ? 'model open' : 'model'}>
        <img src={tempimgSrc} alt="" />
    </div>
    <div className="gallery"> 
      <div className="gallery2">
      {data.map((item, index) => {
        return(
        <div className="pics" key={index} onClick={()=> getImg(item.imgSrc)}>
          <img src={item.imgSrc} alt="" />
        </div>
        )
      })}
        </div>    
    </div> */}
      <Box>
        <Grid container spacing={2} sx={{ p: '0 50px' }}>
          {data.map((item, index) => {
            return (
              <Grid item xs={12} sm={6} lg={4}>
                <img src={item.imgSrc} width='100%' height='300px' alt=""/>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default Gallery;
