import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import img1 from "../Assets/Products/string inverter.jpg";
import img2 from "../Assets/Products/Inverter4.jpeg";
import img3 from "../Assets/MMS-–-HDG.jpg";
import img4 from "../Assets/PV-Modules-–-Canadian.jpg";
import img5 from "../Assets/Products/Inverter.jpg";
import img6 from "../Assets/Products/Ac drum.jpeg";

function Footer() {

  return (
    <div style={{ marginTop: "30px" }}>
      <Grid
        container
        spacing={2}
        sx={{ bgcolor: "#002244", padding: "30px 200px", "@media (max-width: 1261px)": { p: '10px' } }}
      >
        <Grid item sm={3} xs={12}>
          <Typography
            variant="h5"
            sx={{
              color: "white",
              //   fontSize: "20px",
              fontWeight: "600",
              //   borderBottom: "1px solid #00ff2a",
            }}
          >
            Quick Links
          </Typography>
          <hr
            style={{
              backgroundColor: "#00ff2a",
              width: "5%",
              position: "absolute",
              height: "1px",
            }}
          />
          <Box sx={{ display: "flex", gap: "30px", mt: "20px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              {/* <a href='/aboutus' style={{ textDecoration: "none", color: "inherit" }}>
              <Typography
                variant="p"
                sx={{ color: "#bdbdbd", fontWeight: "500" }}
              >
                About Us
              </Typography>
              </a> */}
              <a href='/products' style={{ textDecoration: "none", color: "inherit" }}>
              <Typography
                variant="p"
                sx={{ color: "#bdbdbd", fontWeight: "500" }}
              >
                Products
              </Typography>
              </a>
              <a href='/contactUs' style={{ textDecoration: "none", color: "inherit" }}>
              <Typography
                variant="p"
                sx={{ color: "#bdbdbd", fontWeight: "500" }}
              >
                Contact Us
              </Typography>
              </a>
              <a href="/utility" style={{ textDecoration: "none", color: "inherit" }}>
              <Typography
                variant="p"
                sx={{ color: "#bdbdbd", fontWeight: "500" }}
              >
                Services
              </Typography>
              </a>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              {/* <a href="/utility" style={{ textDecoration: "none", color: "inherit" }}>
              <Typography
                variant="p"
                sx={{ color: "#bdbdbd", fontWeight: "500" }}
              >
                Services
              </Typography>
              </a> */}
              <a href="/blog" style={{ textDecoration: "none", color: "inherit" }}>
              <Typography
                variant="p"
                sx={{ color: "#bdbdbd", fontWeight: "500" }}
              >
                Blog
              </Typography>
              </a>
              <a href='/gallery' style={{ textDecoration: "none", color: "inherit" }}>
              <Typography
                variant="p"
                sx={{ color: "#bdbdbd", fontWeight: "500" }}
              >
                Gallery
              </Typography>
              </a>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={5} xs={12}>
          <Typography
            variant="h5"
            sx={{
              color: "white",
              //   fontSize: "20px",
              fontWeight: "600",
              //   borderBottom: "1px solid #00ff2a",
            }}
          >
            Get In Touch
          </Typography>
          <hr
            style={{
              backgroundColor: "#00ff2a",
              width: "5%",
              position: "absolute",
              height: "1px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              mt: "20px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
              <PhoneIcon
                sx={{
                  background:
                    "linear-gradient(0deg, rgba(43,195,34,1) 0%, rgba(128,146,202,1) 100%)",
                  borderRadius: "50%",
                  padding: "5px",
                  color: "white",
                }}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <Typography
                variant="p"
                sx={{ color: "#bdbdbd", fontWeight: "500" }}
              >
                +91 74492-22939
              </Typography>
              <Typography
                variant="p"
                sx={{ color: "#bdbdbd", fontWeight: "500" }}
              >
                +91 99448-78752
              </Typography>
              <Typography
                variant="p"
                sx={{ color: "#bdbdbd", fontWeight: "500" }}
              >
                +91 70942-22939
              </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <EmailIcon
                sx={{
                  background:
                    "linear-gradient(0deg, rgba(43,195,34,1) 0%, rgba(128,146,202,1) 100%)",
                  borderRadius: "50%",
                  padding: "5px",
                  color: "white",
                }}
              />
              <Typography
                variant="p"
                sx={{ color: "#bdbdbd", fontWeight: "500" }}
              >
                info@elicitgreen.com
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <LocationOnIcon
                sx={{
                  background:
                    "linear-gradient(0deg, rgba(43,195,34,1) 0%, rgba(128,146,202,1) 100%)",
                  borderRadius: "50%",
                  padding: "5px",
                  color: "white",
                }}
              />
              <Typography
                variant="p"
                sx={{ color: "#bdbdbd", fontWeight: "500" }}
              >
                NO: 54, VSSM COMPLEX, 3rd FLOOR, WB ROAD, TRICHY - 620008.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography
            variant="h5"
            sx={{
              color: "white",
              //   fontSize: "20px",
              fontWeight: "600",
              //   borderBottom: "1px solid #00ff2a",
            }}
          >
            Our Products
          </Typography>
          <hr
            style={{
              backgroundColor: "#00ff2a",
              width: "5%",
              position: "absolute",
              height: "1px",
            }}
          />
          <Grid container spacing={1} sx={{ mt: "20px" }}>
            <Grid item xs={4}>
              <img src={img4} width="100%" height="75px" alt="" />
            </Grid>
            <Grid item xs={4}>
              <img src={img2} width="100%" height="75px" alt="" />
            </Grid>
            <Grid item xs={4}>
              <img src={img3} width="100%" height="75px" alt="" />
            </Grid>
            <Grid item xs={4}>
              <img src={img1} width="100%" height="75px" alt="" />
            </Grid>
            <Grid item xs={4}>
              <img src={img5} width="100%" height="75px" alt="" />
            </Grid>
            <Grid item xs={4}>
              <img src={img6} width="100%" height="75px" alt="" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <a href="https://www.instagram.com/elicit._green/" style={{
          position: "fixed",
          zIndex: "9999",
          width: "60px",
          height: "60px",
          bottom: "51%",
          right: "25px",
          background: "linear-gradient(115deg, #f9ce34, #ee2a7b, #6228d7)",
          color: "#fff",
          borderRadius: "50%",
          textAlign: "center",
          lineHeight: "70px",
        }}>
        <i>
          {" "}
          <InstagramIcon />{" "}
        </i>
      </a>
      <a href="https://www.facebook.com/profile.php?id=61563314588328" style={{
          position: "fixed",
          zIndex: "9999",
          width: "60px",
          height: "60px",
          bottom: "41%",
          right: "25px",
          backgroundColor: "#002cff",
          color: "#fff",
          borderRadius: "50%",
          textAlign: "center",
          lineHeight: "70px",
        }}>
        <i>
          {" "}
          <FacebookIcon />{" "}
        </i>
      </a>
      <a href="https://x.com/ElicitGreen" style={{
          position: "fixed",
          zIndex: "9999",
          width: "60px",
          height: "60px",
          bottom: "31%",
          right: "25px",
          backgroundColor: "#000000",
          color: "#fff",
          borderRadius: "50%",
          textAlign: "center",
          lineHeight: "70px",
        }}>
        <i>
          {" "}
          <XIcon />{" "}
        </i>
      </a>
      <a href="https://www.linkedin.com/in/elicit-green-36b088321/" style={{
          position: "fixed",
          zIndex: "9999",
          width: "60px",
          height: "60px",
          bottom: "21%",
          right: "25px",
          backgroundColor: "#0077B5",
          color: "#fff",
          borderRadius: "50%",
          textAlign: "center",
          lineHeight: "70px",
        }}>
        <i>
          <LinkedInIcon />
        </i>
      </a>
      <a
        href="tel:+91 9944878752"
        class="fa-call-icon"
        style={{
          position: "fixed",
          zIndex: "9999",
          width: "60px",
          height: "60px",
          bottom: "1%",
          right: "25px",
          backgroundColor: "#002cff",
          color: "#fff",
          borderRadius: "50%",
          textAlign: "center",
          lineHeight: "70px",
        }}
      >
        <i>
          <PhoneIcon />
        </i>
      </a>
      <a
        href="https://wa.me/9944878752?text=Hi!"
        style={{
          position: "fixed",
          zIndex: "9999",
          width: "60px",
          height: "60px",
          bottom: "11%",
          right: "25px",
          backgroundColor: "#25D366",
          color: "#fff",
          borderRadius: "50%",
          textAlign: "center",
          lineHeight: "70px",
        }}
      >
        <i>
          <WhatsAppIcon />
        </i>
      </a>
    </div>
  );
}

export default Footer;
