import React from "react";
import coverimg from "../../Assets/Gallery/g45.jpeg";
import captiveimg from "../../Assets/Gallery/g46.jpeg";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import RecyclingIcon from "@mui/icons-material/Recycling";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import img3 from "../../Assets/Services/Utility/utility cover.jpeg";
import img4 from "../../Assets/Services/Utility/img4.png";
import maintenance from "../../Assets/Services/Utility/Maintenance.jpg";
import "./Utility.css";

const Utility = () => {
  return (
    <div>
      <Box
        position="relative"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <img src={img3} width="100%" height="500px" alt="" />
        <Box
          position="absolute"
          sx={{
            display: "flex",
            width: "50%",
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0,0,0,0.6)",
            height: "200px",
            borderRadius: "50px",
            "@media (max-width: 1100px)": { width: "80%" },
            "@media (max-width: 500px)": { width: "100%" },
          }}
        ></Box>
        <Box
          position="absolute"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "600", color: "white" }}>
            UTILITY SERVICES
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={1} sx={{ marginTop: "50px" }}>
        <Box
          sx={{
            padding: "0 70px",
            display: "flex",
            gap: "20px",
            "@media (max-width: 800px)": { flexWrap: "wrap" },
          }}
        >
          <Grid item xs={12} md={6}>
            <img
              src={coverimg}
              width="100%"
              height="100%"
              alt=""
              style={{ borderRadius: "20px" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: "32px", fontWeight: "600", color: "#00ff2a" }}
            >
              Utility-Scale Solar Power Solutions
            </Typography>
            <Typography
              paragraph
              sx={{
                pt: "20px",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "27px",
                letterSpacing: "1px",
              }}
            >
              For large-scale energy needs, utility-scale solar power solutions
              offer a robust and reliable source of renewable electricity.
              ELICIT GREEN ENERGY specializes in designing, installing, and
              maintaining utility-scale solar captive model energy systems that
              can power entire communities, commercial facilities, or industrial
              operations.
            </Typography>
            <Typography
              paragraph
              sx={{
                pt: "20px",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "27px",
                letterSpacing: "1px",
              }}
            >
              Our utility-scale solar solutions are tailored to the unique
              requirements of each project, leveraging the most advanced solar
              technologies and engineering expertise to maximize energy
              generation and operational efficiency.
            </Typography>
          </Grid>
        </Box>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            margin: "50px 0 20px 0",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Typography
            variant="p"
            sx={{
              display: "inline-grid",
              fontSize: "22px",
              fontWeight: "600",
              backgroundColor: "#40E0D0",
              padding: "10px 20px",
              borderRadius: "10px",
              textAlign: "center",
            }}
          >
            Why Us For Utility-Scale Solar Systems
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          sx={{
            bgcolor: "#dbdbdb",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 70px !important",
            gap: "30px",
            height: "350px",
            lineHeight: "27px",
            letterSpacing: "1px",

            "@media (max-width: 426px)": { padding: "0 30px" },
            "@media (max-width: 800px)": { padding: "10px !important" },
          }}
        >
          <Typography
            variant="p"
            sx={{ fontSize: "22px", fontWeight: "600", textAlign: "center" }}
          >
            Megawatt-Scale Capacity
          </Typography>
          <Typography variant="p" sx={{ textAlign: "center" }}>
            Our utility-scale solar installations can range from several hundred
            kilowatts to multiple megawatts, providing the scale required to
            meet the energy demands of large-scale consumers.
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          sx={{
            bgcolor: "#f0df89",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 70px 0 70px !important",
            gap: "30px",
            height: "350px",
            lineHeight: "27px",
            letterSpacing: "1px",
            "@media (max-width: 426px)": { padding: "0 30px" },
            "@media (max-width: 800px)": { padding: "10px !important" },
          }}
        >
          <Typography
            variant="p"
            sx={{ fontSize: "22px", fontWeight: "600", textAlign: "center" }}
          >
            Proven Track Record
          </Typography>
          <Typography variant="p" sx={{ textAlign: "center" }}>
            With years of experience in the utility-scale solar industry, we
            have a proven track record of delivering successful projects that
            meet or exceed our clients' energy goals.
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          sx={{
            bgcolor: "#f0df89",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 70px 0 70px !important",
            gap: "30px",
            height: "350px",
            lineHeight: "27px",
            letterSpacing: "1px",
            "@media (max-width: 426px)": { padding: "0 30px" },
            "@media (max-width: 800px)": { padding: "10px !important" },
            "@media (max-width: 899px)": { bgcolor: "#dbdbdb" },
          }}
        >
          <Typography
            variant="p"
            sx={{ fontSize: "22px", fontWeight: "600", textAlign: "center" }}
          >
            Site Preparation
          </Typography>
          <ul>
            <li
              style={{
                marginLeft: "15px",
                overflow: "visible",
                display: "list-item",
              }}
            >
              Site survey and assessment to evaluate terrain, accessibility,
              existing infrastructure, etc.
            </li>
            <li
              style={{
                marginLeft: "15px",
                overflow: "visible",
                display: "list-item",
              }}
            >
              Land clearing, grading, and leveling to prepare the site for solar
              panel installation
            </li>
            <li
              style={{
                marginLeft: "15px",
                overflow: "visible",
                display: "list-item",
              }}
            >
              Construction of access roads, security fencing, and other
              ancillary structures.
            </li>
          </ul>
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          sx={{
            bgcolor: "#dbdbdb",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 70px 0 70px !important",
            gap: "30px",
            height: "350px",
            lineHeight: "27px",
            letterSpacing: "1px",
            "@media (max-width: 426px)": { padding: "0 30px" },
            "@media (max-width: 800px)": { padding: "10px !important" },
            "@media (max-width: 899px)": { bgcolor: "#f0df89" },
          }}
        >
          <Typography
            variant="p"
            sx={{ fontSize: "22px", fontWeight: "600", textAlign: "center" }}
          >
            Solar Panel Installation
          </Typography>
          <ul>
            <li
              style={{
                marginLeft: "15px",
                overflow: "visible",
                display: "list-item",
              }}
            >
              Laying out the solar panel array based on the site design.
            </li>
            <li
              style={{
                marginLeft: "15px",
                overflow: "visible",
                display: "list-item",
              }}
            >
              Installing the solar panel mounting structures, either fixed-tilt
              or tracking systems. - Mounting the solar panels onto the
              structures and connecting them in series/parallel.
            </li>
            <li
              style={{
                marginLeft: "15px",
                overflow: "visible",
                display: "list-item",
              }}
            >
              Electrical wiring to connect the solar arrays to the inverters and
              transformers.
            </li>
          </ul>
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          sx={{
            bgcolor: "#dbdbdb",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 70px 0 70px !important",
            gap: "30px",
            height: "350px",
            lineHeight: "27px",
            letterSpacing: "1px",
            "@media (max-width: 426px)": { padding: "0 30px" },
            "@media (max-width: 800px)": { padding: "10px !important" },
          }}
        >
          <Typography
            variant="p"
            sx={{ fontSize: "22px", fontWeight: "600", textAlign: "center" }}
          >
            Inverter and Electrical System
          </Typography>
          <ul>
            <li
              style={{
                marginLeft: "15px",
                overflow: "visible",
                display: "list-item",
              }}
            >
              Installing central inverters or string inverters to convert the DC
              power to AC.
            </li>
            <li
              style={{
                marginLeft: "15px",
                overflow: "visible",
                display: "list-item",
              }}
            >
              Setting up the electrical infrastructure including transformers,
              switchgears, and grid interconnection.
            </li>
            <li
              style={{
                marginLeft: "15px",
                overflow: "visible",
                display: "list-item",
              }}
            >
              Completing the medium/high voltage electrical connections and
              testing.
            </li>
          </ul>
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          sx={{
            bgcolor: "#f0df89",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 70px 0 70px !important",
            gap: "30px",
            height: "350px",
            lineHeight: "27px",
            letterSpacing: "1px",
            "@media (max-width: 426px)": { padding: "0 30px" },
            "@media (max-width: 800px)": { padding: "10px !important" },
          }}
        >
          <Typography
            variant="p"
            sx={{ fontSize: "22px", fontWeight: "600", textAlign: "center" }}
          >
            Commissioning and Testing
          </Typography>
          <ul>
            <li
              style={{
                marginLeft: "15px",
                overflow: "visible",
                display: "list-item",
              }}
            >
              Performing electrical safety and functionality checks on all
              components.
            </li>
            <li
              style={{
                marginLeft: "15px",
                overflow: "visible",
                display: "list-item",
              }}
            >
              Conducting performance tests to validate the power output and
              efficiency.
            </li>
            <li
              style={{
                marginLeft: "15px",
                overflow: "visible",
                display: "list-item",
              }}
            >
              Completing grid synchronisation and obtaining necessary
              approvals/certifications.
            </li>
            <li
              style={{
                marginLeft: "15px",
                overflow: "visible",
                display: "list-item",
              }}
            >
              Training the operations and maintenance team on system monitoring
              and troubleshooting.
            </li>
          </ul>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          sm={12}
          md={6}
          sx={{
            bgcolor: "#bfffbf",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "30px",
            height: "350px",
            lineHeight: "27px",
            letterSpacing: "1px",
          }}
        >
          <img src={maintenance} width="100%" height="100%" alt="" />
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          sx={{
            bgcolor: "#dbdbdb",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 70px 0 70px !important",
            gap: "30px",
            height: "350px",
            lineHeight: "27px",
            letterSpacing: "1px",
            "@media (max-width: 426px)": { padding: "0 30px" },
            "@media (max-width: 800px)": { padding: "10px !important" },
          }}
        >
          <Typography
            variant="p"
            sx={{ fontSize: "22px", fontWeight: "600", textAlign: "center" }}
          >
            Operations and Maintenance
          </Typography>
          <ul>
            <li
              style={{
                marginLeft: "15px",
                overflow: "visible",
                display: "list-item",
              }}
            >
              Establishing a preventive maintenance schedule for regular
              cleaning, inspection, and servicing.
            </li>
            <li
              style={{
                marginLeft: "15px",
                overflow: "visible",
                display: "list-item",
              }}
            >
              Implementing a remote monitoring system to track performance and
              identify any issues.
            </li>
            <li
              style={{
                marginLeft: "15px",
                overflow: "visible",
                display: "list-item",
              }}
            >
              Ensuring compliance with all relevant regulations and safety
              standards.
            </li>
          </ul>
        </Grid>
      </Grid>
      <Box
        sx={{ display: "flex", justifyContent: "center", position: "relative" }}
      >
        <Box
          sx={{
            width: "100%",
            "@media (max-width: 1100px)": { height: "500px" },
          }}
        >
          {/* <img src={captiveimg} width="100%" height="100%" alt="" /> */}
          <div className="back-img2"></div>
        </Box>
        <Box
          position="absolute"
          sx={{
            top: "100px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "50%",
            zIndex: "9999",
            "@media (max-width: 1100px)": { width: "100%" },
          }}
        >
          <Typography
            variant="p"
            sx={{ fontSize: "35px", color: "white", fontWeight: "700" }}
          >
            WHY CAPTIVE?
          </Typography>
          <Typography
            paragraph
            sx={{
              pt: "20px",
              color: "white",
              fontSize: "20px",
              fontWeight: "600",
              lineHeight: "27px",
              letterSpacing: "1px",
              textAlign: "center",
            }}
          >
            For businesses and industries with significant on-site energy
            demands, captive solar power systems provide a tailored solution to
            generate renewable electricity directly for your operations. Captive
            solar systems are designed to meet your specific energy
            requirements, reducing your reliance on the grid and providing
            long-term cost savings.
          </Typography>
        </Box>
      </Box>
      <Grid container sx={{ pt: "30px", bgcolor: "#8a8a8a" }}>
        <Grid item xs={12}>
          <Typography
            variant="p"
            sx={{
              fontSize: "25px",
              fontWeight: "600",
              textTransform: "uppercase",
              display: "flex",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            Benefits of Captive Solar Power Systems
          </Typography>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            p: "50px 100px",
            "@media (max-width: 600px)": { p: "50px 10px" },
          }}
        >
          <Grid item xs={12} sm={4}>
            <Card
              sx={{
                bgcolor: "#343536",
                height: "280px",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <CardContent
                sx={{
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <PriceCheckIcon
                  sx={{
                    fontSize: "70px",
                    backgroundColor: "brown",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px",
                    mb: "20px",
                  }}
                />
                <Typography variant="p" sx={{ fontWeight: "600" }}>
                  ENERGY COST SAVINGS
                </Typography>
                <br />
                <Typography
                  variant="p"
                  sx={{ lineHeight: "25px", letterSpacing: "1px" }}
                >
                  By generating your own solar power, you can significantly
                  reduce your electricity expenses and insulate your business
                  from fluctuating utility rates.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card
              sx={{
                bgcolor: "#343536",
                height: "280px",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <CardContent
                sx={{
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <RecyclingIcon
                  sx={{
                    fontSize: "70px",
                    backgroundColor: "brown",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px",
                    mb: "20px",
                  }}
                />
                <Typography variant="p" sx={{ fontWeight: "600" }}>
                  ENVIRONMENTAL SUSTAINABILITY
                </Typography>
                <br />
                <Typography
                  variant="p"
                  sx={{ lineHeight: "25px", letterSpacing: "1px" }}
                >
                  Captive solar enables you to reduce your carbon footprint and
                  demonstrate your commitment to sustainability, which can be
                  valuable for your brand and reputation.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card
              sx={{
                bgcolor: "#343536",
                height: "280px",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <CardContent
                sx={{
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <EmojiObjectsIcon
                  sx={{
                    fontSize: "70px",
                    backgroundColor: "brown",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px",
                    mb: "20px",
                  }}
                />
                <Typography variant="p" sx={{ fontWeight: "600" }}>
                  CUSTOMIZED SOLUTIONS
                </Typography>
                <br />
                <Typography
                  variant="p"
                  sx={{ lineHeight: "25px", letterSpacing: "1px" }}
                >
                  Our team will work closely with you to design a captive solar
                  system that aligns with your energy needs, available space,
                  and budget requirements.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <hr style={{ backgroundColor: "#545454", width: "100%" }} />
        <Grid item xs={12} mt="10px" padding="0 20px">
          <Typography
            paragraph
            sx={{ color: "white", fontWeight: "500", textAlign: "center" }}
          >
            Whether you're looking to power a utility-scale grid or meet the
            energy demands of your business, ELICIT GREEN ENERGY has the
            expertise and resources to deliver comprehensive solar energy
            solutions that drive long-term value and sustainability.
          </Typography>
        </Grid>
      </Grid>

      {/* <Box sx={{ bgcolor: "#cdcdcd", padding: "50px 0" }}> */}
      {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            variant="p"
            sx={{
              fontSize: "24px",
              fontWeight: "600",
              borderBottom: "1px solid green",
              borderWidth: "medium",
            }}
          >
            TAX BENEFITS
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          mt="40px"
          padding="0px 70px"
          sx={{ "@media (max-width: 600px)": { padding: "0 10px" } }}
        >
          <Grid item md={6} xs={12}>
            <img
              src={img4}
              width="100%"
              height="100%"
              alt=""
              style={{ borderRadius: "30px" }}
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{ display: "flex", flexDirection: "column", gap: "30px" }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Typography
                variant="p"
                sx={{
                  fontSize: "18px",
                  fontWeight: "600",
                  width: "fit-content",
                  backgroundColor: "#bff1bf",
                  borderRadius: "10px",
                  padding: "5px",
                }}
              >
                Tax Deductions and Incentives for Captive Solar Power Plants
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "25px",
                  letterSpacing: "1px",
                  paddingLeft: "20px",
                }}
              >
                Investing in a captive solar power plant can provide significant
                tax benefits and deductions for businesses and organizations.
                Captive solar refers to a solar energy system that is owned and
                operated by the end-user, rather than a third-party provider.
                These tax incentives help offset the initial capital investment
                and ongoing operational costs of a captive solar system.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Typography
                variant="p"
                sx={{
                  fontSize: "18px",
                  fontWeight: "600",
                  width: "fit-content",
                  backgroundColor: "#bff1bf",
                  borderRadius: "10px",
                  padding: "5px",
                }}
              >
                Accelerated Depreciation
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "25px",
                  letterSpacing: "1px",
                  paddingLeft: "20px",
                }}
              >
                One of the primary tax benefits for captive solar is the ability
                to utilize accelerated depreciation. Solar energy systems can be
                depreciated much faster than other types of equipment, allowing
                businesses to deduct a large portion of the system's cost from
                their taxable income in the early years of operation.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Typography
                variant="p"
                sx={{
                  fontSize: "18px",
                  fontWeight: "600",
                  width: "fit-content",
                  backgroundColor: "#bff1bf",
                  borderRadius: "10px",
                  padding: "5px",
                }}
              >
                Investment Tax Credit (ITC)
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "25px",
                  letterSpacing: "1px",
                  paddingLeft: "20px",
                }}
              >
                The federal Investment Tax Credit (ITC) is a dollar-for-dollar
                reduction in the income tax owed by businesses that invest in
                solar energy systems.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Typography
                variant="p"
                sx={{
                  fontSize: "18px",
                  fontWeight: "600",
                  width: "fit-content",
                  backgroundColor: "#bff1bf",
                  borderRadius: "10px",
                  padding: "5px",
                }}
              >
                State and Local Incentives
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "25px",
                  letterSpacing: "1px",
                  paddingLeft: "20px",
                }}
              >
                Many state and local governments also offer additional tax
                credits, rebates, and other financial incentives for businesses
                that invest in captive solar power. These can include:
              </Typography>
              <ul>
                <li style={{ marginLeft: "70px", overflow: "visible" }}>
                  State tax credits
                </li>
                <li style={{ marginLeft: "70px", overflow: "visible" }}>
                  Property tax exemptions
                </li>
                <li style={{ marginLeft: "70px", overflow: "visible" }}>
                  Sales tax exemptions on solar equipment
                </li>
                <li style={{ marginLeft: "70px", overflow: "visible" }}>
                  Utility bill credits or net metering programs
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid> */}
      {/* </Box> */}
      <Box position="relative">
        <div className="tax"></div>
        <Box
          sx={{
            position: "absolute",
            top: "5%",
            zIndex: "9999",
            // display: "flex",
            // flexDirection: "column",
            // alignItems: "center",
            // width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontWeight: "600",
                textAlign: "center",
                color: "#d1d1d1",
                "@media (max-width: 600px)": {
                  fontSize: "35px",
                  color: "azure",
                },
              }}
            >
              TAX BENEFITS
            </Typography>

            <Typography
              variant="p"
              sx={{
                textAlign: "center",
                mt: "30px",
                fontSize: "18px",
                fontWeight: "500",
                lineHeight: "30px",
                color: "white",
                "@media (max-width: 600px)": { color: "azure" },
              }}
            >
              Solar Energy Tax Benefits refer to financial incentives provided
              by governments to reduce the cost of installing and operating
              solar power systems. These benefits, including tax credits,
              deductions, and accelerated depreciation, help businesses and
              individuals lower their tax liabilities, making solar energy more
              affordable and encouraging the adoption of renewable energy
              solutions.
            </Typography>
          </Box>
          <Grid
            container
            spacing={3}
            mt="50px"
            p="0 100px"
            sx={{ "@media(max-width: 900px)": { p: "10px" } }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "20px",
                padding: "20px",
              }}
            >
              {/* <MilitaryTechIcon sx={{ fontSize: "75px", color: "white" }} /> */}
              <Box>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "22px",
                    fontWeight: "500",
                    color: "#ffffff",
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                  Tax Benefits for Captive Solar Power Plants
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="p"
                  sx={{
                    color: "#ffffff",
                    textAlign: "center",
                    display: "flex",
                    fontWeight: "300",
                    lineHeight: "25px",
                  }}
                >
                  Investing in a captive solar power plant offers substantial
                  tax benefits and deductions for businesses. As an
                  end-user-owned system, captive solar helps offset initial
                  capital investments and ongoing operational costs, making it a
                  smart choice for sustainable energy solutions.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "20px",
                padding: "20px",
              }}
            >
              {/* <WorkspacePremiumIcon
                  sx={{ fontSize: "75px", color: "white" }}
                /> */}
              <Box>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "22px",
                    fontWeight: "500",
                    color: "#ffffff",
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                  Accelerated Depreciation
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="p"
                  sx={{
                    color: "#ffffff",
                    textAlign: "center",
                    display: "flex",
                    fontWeight: "300",
                    lineHeight: "25px",
                  }}
                >
                  One of the key tax advantages of captive solar is accelerated
                  depreciation. Solar energy systems can be depreciated
                  significantly faster than other equipment, enabling businesses
                  to deduct a substantial portion of the system's cost from
                  taxable income in the initial years of operation.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "20px",
                padding: "20px",
              }}
            >
              {/* <EngineeringIcon sx={{ fontSize: "75px", color: "white" }} /> */}
              <Box>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "22px",
                    fontWeight: "500",
                    color: "#ffffff",
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                  Investment Tax Credit (ITC)
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="p"
                  sx={{
                    color: "#ffffff",
                    textAlign: "center",
                    display: "flex",
                    fontWeight: "300",
                    lineHeight: "25px",
                  }}
                >
                  The federal Investment Tax Credit (ITC) is a dollar-for-dollar
                  reduction in the income tax owed by businesses that invest in
                  solar energy systems.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "20px",
                padding: "20px",
              }}
            >
              {/* <ManageHistoryIcon sx={{ fontSize: "75px", color: "white" }} /> */}
              <Box>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "22px",
                    fontWeight: "500",
                    color: "#ffffff",
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                  State and Local Incentives
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="p"
                  sx={{
                    color: "#ffffff",
                    textAlign: "center",
                    display: "flex",
                    fontWeight: "300",
                    lineHeight: "25px",
                  }}
                >
                  Many state and local governments also offer additional tax
                  credits, rebates, and other financial incentives for
                  businesses that invest in captive solar power. These can
                  include:
                </Typography>
                <ul
                  style={{
                    color: "#ffffff",

                    fontWeight: "300",
                    lineHeight: "25px",
                  }}
                >
                  <li
                    style={{
                      marginLeft: "15px",
                      overflow: "visible",
                      display: "list-item",
                    }}
                  >
                    State tax credits
                  </li>
                  <li
                    style={{
                      marginLeft: "15px",
                      overflow: "visible",
                      display: "list-item",
                    }}
                  >
                    Property tax exemptions
                  </li>
                  <li
                    style={{
                      marginLeft: "15px",
                      overflow: "visible",
                      display: "list-item",
                    }}
                  >
                    Sales tax exemptions on solar equipment
                  </li>
                  <li
                    style={{
                      marginLeft: "15px",
                      overflow: "visible",
                      display: "list-item",
                    }}
                  >
                    Utility bill credits or net metering programs
                  </li>
                </ul>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default Utility;
