import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import CallMadeIcon from "@mui/icons-material/CallMade";
import React from "react";
import img1 from "../Assets/AboutUs/advn1.jpg";
import { Link } from "react-router-dom";

const Blog = () => {
  return (
    <Box sx={{ padding: "50px 100px", '@media (max-width: 700px)': { padding: '10px' } }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <Card sx={{ position: "relative" }}>
            <CardMedia component="img" height="250" image={img1} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "absolute",
                backgroundColor: "#34eb46",
                textAlign: "center",
                color: "white",
                fontWeight: "700",
                padding: "10px",
                right: "1%",
                top: "58%",
              }}
            >
              <Typography variant="p">27</Typography>
              <Typography variant="p">SEP</Typography>
            </Box>
            <CardContent sx={{ mt: '20px' }}>
              <Typography variant="p" sx={{ fontSize: '18px', fontWeight: '600' }}>
                Impact of Solar Energy on Reducing Carbon
              </Typography>
            </CardContent>
            <CardActions>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Link to='/blog1' style={{ textDecoration: 'none', color: 'inherit' }} >
                <Button>READ MORE</Button>
                <CallMadeIcon />
                </Link>
              </Box>
            </CardActions>
          </Card>
        </Grid>
        {/* <Grid item xs={4}>
          <Card sx={{ position: "relative" }}>
            <CardMedia component="img" height="250" image={img1} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "absolute",
                backgroundColor: "#34eb46",
                textAlign: "center",
                color: "white",
                fontWeight: "700",
                padding: "10px",
                right: "1%",
                top: "58%",
              }}
            >
              <Typography variant="p">27</Typography>
              <Typography variant="p">SEP</Typography>
            </Box>
            <CardContent sx={{ mt: '20px' }}>
              <Typography variant="p" sx={{ fontSize: '18px', fontWeight: '600' }}>
                Impact of Solar Energy on Reducing Carbon
              </Typography>
            </CardContent>
            <CardActions>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Button>READ MORE</Button>
                <CallMadeIcon />
              </Box>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ position: "relative" }}>
            <CardMedia component="img" height="250" image={img1} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "absolute",
                backgroundColor: "#34eb46",
                textAlign: "center",
                color: "white",
                fontWeight: "700",
                padding: "10px",
                right: "1%",
                top: "58%",
              }}
            >
              <Typography variant="p">27</Typography>
              <Typography variant="p">SEP</Typography>
            </Box>
            <CardContent sx={{ mt: '20px' }}>
              <Typography variant="p" sx={{ fontSize: '18px', fontWeight: '600' }}>
                Impact of Solar Energy on Reducing Carbon
              </Typography>
            </CardContent>
            <CardActions>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Button>READ MORE</Button>
                <CallMadeIcon />
              </Box>
            </CardActions>
          </Card>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default Blog;
