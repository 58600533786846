import React, { useState } from "react";
import ToggleCard from "./Card";
import { Box, Grid, Typography } from "@mui/material";
import img3 from "../Assets/bg3.jpeg";
import pvmodule from "../Assets/Products/PV.jpg";
import inverter from "../Assets/Products/Inverter.jpg";
import inverter2 from "../Assets/Products/Inverter2.jpeg";
import inverter3 from "../Assets/Products/Inverter3.jpeg";
import inverter4 from "../Assets/Products/Inverter4.jpeg";
import inverter5 from "../Assets/Products/Inverter5.jpeg";
import earthing from "../Assets/Products/Earthing.webp";
import earthing3 from "../Assets/Products/earthing3.jpeg";
import earthing4 from "../Assets/Products/earthing4.jpeg";
import earthing5 from "../Assets/Products/earthing5.jpeg";
import cable from "../Assets/Products/Ac drum.jpeg";
import cable2 from "../Assets/Products/Ac drum2.jpeg";
import acdc from "../Assets/Products/AC-DC Cables.jpg";
import acdc1 from "../Assets/Products/ACDC1.jpeg";
import acdc2 from "../Assets/Products/ACDC2.jpeg";
import acdc3 from "../Assets/Products/ACDC3.jpeg";
import terminal from "../Assets/Products/Terminal.jpg";
import transformer from "../Assets/Products/Transforme.jpg";
import lt from "../Assets/Products/Duct.jpg";
import lt2 from "../Assets/Products/LT Panel.jpeg";
import fuse from "../Assets/Products/Fuse.jpeg";
import LA from "../Assets/Products/LA.jpeg";
import ht from "../Assets/Products/HT Panel.png";
import battery from "../Assets/Products/USB.jpeg";
import safety from "../Assets/Products/Safety.webp";
import htbreaker from "../Assets/Products/htbreaker.jpeg";

const Products = () => {
  // Step 1: State to manage which card is expanded
  const [expandedCardId, setExpandedCardId] = useState(null);

  // Step 2: Toggle card content visibility based on clicked card's id
  const handleImageClick = (cardId) => {
    // If the same card is clicked again, collapse it (toggle)
    if (expandedCardId === cardId) {
      setExpandedCardId(null);
    } else {
      setExpandedCardId(cardId); // Expand the clicked card
    }
  };

  // Step 3: Array of card data
  const cardData = [
    {
      id: 1,
      title: "PV MODULE",
      imageUrl: [pvmodule],
      contentTitle: "BRAND",
      brands: ["Renewsys", "Goldi", "Ryzen", "Pahal", "Panasonic", "Waaree"],
    },
    {
      id: 2,
      title: "INVERTER",
      imageUrl: [inverter, inverter2, inverter3, inverter4, inverter5],
      contentTitle: "BRAND",
      brands: [
        "Sofar",
        "Solis",
        "Growatt",
        "Goodwe",
        "Havells",
        "Sungrow",
        "Fronius",
        "ABB",
      ],
    },
    {
      id: 3,
      title: "EARTHING MATERIALS LA",
      imageUrl: [earthing, LA, earthing3, earthing4, earthing5],
      contentTitle: "BRAND",
      brands: ["Elicit The Green Energy"],
    },
    {
      id: 4,
      title: "CABLE",
      imageUrl: [acdc, cable, cable2],
      contentTitle: "BRAND",
      brands: ["Kec", "Kei", "Havells", "Sichem", "Lapp"],
    },
    {
      id: 5,
      title: "AC/DC DISTRIBUTION",
      imageUrl: [acdc1, acdc2, acdc3],
      contentTitle: "BRAND",
      brands: ["Polycab", "Elicit The Green Energy"],
    },
    {
      id: 6,
      title: "TERMINAL BOX, FUSE, MC4",
      imageUrl: [terminal, fuse],
      contentTitle: "BRAND",
      brands: ["Bussman", "Eaton"],
    },
    {
      id: 7,
      title: "TRANSFORMER",
      imageUrl: [transformer],
      contentTitle: "BRAND",
      brands: ["Voltamp", "Danish", "Indotech", "Esennar", "SPS"],
    },
    {
      id: 8,
      title: "LT PANELS AND BUS DUCT",
      imageUrl: [lt, lt2],
      contentTitle: "BRAND",
      brands: ["Elicit The Green Energy"],
    },
    {
      id: 9,
      title: "HT PANELS & HT BREAKERS",
      imageUrl: [ht, htbreaker],
      contentTitle: "BRAND",
      brands: ["Siemens", "CG", "ABB", "System Control", "Megawin", "Sriwin"],
    },
    {
      id: 10,
      title: "BATTERIES",
      imageUrl: [battery],
      contentTitle: "BRAND",
      brands: ["Amaron", "Exide", "Microtech"],
    },
    {
      id: 11,
      title: "ALL SAFETY EQUIPMENT",
      imageUrl: [safety],
      contentTitle: "BRAND",
      brands: ["Elicit The Green Energy"],
    },
    // Add more card data here
  ];

  return (
    <div>
      <Box
        position="relative"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <img src={img3} width="100%" height="500px" alt="" />
        <Box
          position="absolute"
          sx={{
            display: "flex",
            width: "50%",
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0,0,0,0.6)",
            height: "200px",
            borderRadius: "50px",
            "@media (max-width: 1100px)": { width: "80%" },
            "@media (max-width: 500px)": { width: "100%" },
          }}
        ></Box>
        <Box
          position="absolute"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "600", color: "white" }}>
            PRODUCTS
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "50px 100px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          "@media(max-width: 600px)": { padding: "10px" },
        }}
      >
        <Grid container spacing={2}>
          {cardData.map((card) => (
            <Grid item xs={12} sm={6} lg={4}>
              <ToggleCard
                key={card.id}
                id={card.id}
                title={card.title}
                imageUrl={card.imageUrl}
                contentTitle={card.contentTitle}
                isExpanded={expandedCardId === card.id} // Check if this card is expanded
                handleImageClick={handleImageClick} // Pass the click handler
                brands={card.brands}
                cardData={cardData}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default Products;
