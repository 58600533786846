import React from "react";
import { Box, Grid, Typography, Card, CardContent } from "@mui/material";
import img1 from "../../Assets/Services/RoofTop/OffGrid/img1.jpg";
import img2 from "../../Assets/Services/RoofTop/OffGrid/img2.jpg";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import MicrowaveIcon from "@mui/icons-material/Microwave";
import GasMeterIcon from "@mui/icons-material/GasMeter";

const OffGrid = () => {
  return (
    <div>
      <Box
        position="relative"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <img src={img1} width="100%" height="500px" alt="" />
        <Box
          position="absolute"
          sx={{
            display: "flex",
            width: "50%",
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0,0,0,0.6)",
            height: "200px",
            borderRadius: "50px",
            "@media (max-width: 1100px)": { width: "80%" },
            "@media (max-width: 500px)": { width: "100%" },
          }}
        ></Box>
        <Box
          position="absolute"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "600", color: "white" }}>
            ROOFTOP OFF-GRID SERVICES
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2} mt="50px" p="0 70px">
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="p"
            sx={{ fontSize: "32px", fontWeight: "600", color: "#00ff2a" }}
          >
            Off-Grid Rooftop Solar Systems
          </Typography>
          <br />
          <Typography
            variant="p"
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "27px",
              letterSpacing: "1px",
            }}
          >
            For remote locations or areas with unreliable grid access, an
            off-grid solar system is an ideal solution. Off-grid solar systems
            operate independently, without being connected to the utility grid.
            These systems rely on solar panels, batteries, and other components
            to generate, store, and manage the electricity needed to power your
            home or business.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src={img2}
            width="100%"
            height="100%"
            alt=""
            style={{ borderRadius: "20px" }}
          />
        </Grid>
      </Grid>
      <Box mt="40px">
        <Typography
          variant="p"
          sx={{
            fontSize: "30px",
            fontWeight: "600",
            display: "flex",
            justifyContent: "center",
          }}
        >
          How Does it Work?
        </Typography>
        <Grid container spacing={2} mt="40px" p="0 70px">
          <Grid item sm={12} md={3}>
            <Card
              sx={{
                bgcolor: "#343536",
                height: "280px",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                },
                "@media (min-width: 900px)": { height: "350px" },
                "@media (min-width: 1210px)": { height: "280px" },
              }}
            >
              <CardContent
                sx={{
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <SolarPowerIcon
                  sx={{
                    fontSize: "70px",
                    backgroundColor: "brown",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px",
                    mb: "20px",
                  }}
                />
                <Typography variant="p" sx={{ fontWeight: "600" }}>
                  SOLAR PANELS
                </Typography>
                <br />
                <Typography
                  variant="p"
                  sx={{ lineHeight: "25px", letterSpacing: "1px" }}
                >
                  Our high-efficiency solar panels capture sunlight and convert
                  it into direct current (DC) electricity.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} md={3}>
            <Card
              sx={{
                bgcolor: "#343536",
                height: "280px",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                },
                "@media (min-width: 900px)": { height: "350px" },
                "@media (min-width: 1210px)": { height: "280px" },
              }}
            >
              <CardContent
                sx={{
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <MicrowaveIcon
                  sx={{
                    fontSize: "70px",
                    backgroundColor: "brown",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px",
                    mb: "20px",
                  }}
                />
                <Typography variant="p" sx={{ fontWeight: "600" }}>
                  INVERTER
                </Typography>
                <br />
                <Typography
                  variant="p"
                  sx={{ lineHeight: "25px", letterSpacing: "1px" }}
                >
                  The DC electricity is then converted into alternating current
                  (AC) electricity, which is compatible with your home's
                  electrical system.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} md={3}>
            <Card
              sx={{
                bgcolor: "#343536",
                height: "280px",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                },
                "@media (min-width: 900px)": { height: "350px" },
                "@media (min-width: 1210px)": { height: "280px" },
              }}
            >
              <CardContent
                sx={{
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <GasMeterIcon
                  sx={{
                    fontSize: "70px",
                    backgroundColor: "brown",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px",
                    mb: "20px",
                  }}
                />
                <Typography variant="p" sx={{ fontWeight: "600" }}>
                  CHARGE CONTROLLER
                </Typography>
                <br />
                <Typography
                  variant="p"
                  sx={{ lineHeight: "25px", letterSpacing: "1px" }}
                >
                  Regulates the flow of energy between the solar panels and
                  batteries.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} md={3}>
                    <Card
                      sx={{
                        bgcolor: "#343536",
                        height: "280px",
                        "&:hover": {
                          transform: "scale(1.05)",
                          boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                        },
                        "@media (min-width: 900px)": { height: "350px" },
                        "@media (min-width: 1210px)": { height: "280px" },
                      }}
                    >
                      <CardContent
                        sx={{
                          color: "white",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <GasMeterIcon
                          sx={{
                            fontSize: "70px",
                            backgroundColor: "brown",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "5px",
                            mb: "20px",
                          }}
                        />
                        <Typography variant="p" sx={{ fontWeight: "600" }}>
                          Batteries
                        </Typography>
                        <br />
                        <Typography
                          variant="p"
                          sx={{ lineHeight: "25px", letterSpacing: "1px" }}
                        >
                          Store excess energy for use during nighttime or cloudy days.
                        </Typography>
                      </CardContent>
                    </Card>
          </Grid>
        </Grid>
        <Box mt="80px" textAlign="center">
          <Typography
            variant="p"
            // sx={{
            //   fontSize: "25px",
            //   fontWeight: "600",
            //   display: "flex",
            //   justifyContent: "center",
            //   textAlign: "center",
            // }}
            sx={{
              display: "inline-grid",
              fontSize: "22px",
              fontWeight: "600",
              backgroundColor: "#40E0D0",
              padding: "10px 20px",
              borderRadius: "10px",
              textAlign: "center",
            }}
          >
            Key Benefits of On-Grid Rooftop Solar
          </Typography>
          <Grid container mt="40px">
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                bgcolor: "#f0df89",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "100px 70px",
                gap: "30px",
                height: "350px",
                "@media (max-width: 800px)": { padding: "10px" },
              }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "22px", fontWeight: "600" }}
              >
                Energy Independence
              </Typography>
              <Typography
                variant="p"
                sx={{
                  textAlign: "center",
                  lineHeight: "30px",
                  "@media (max-width: 1000px)": { lineHeight: "20px" },
                }}
              >
                Off-grid solar systems provide complete energy independence,
                ensuring you have a reliable and consistent power supply, even
                during grid outages or blackouts.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                bgcolor: "#dbdbdb",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "100px 70px",
                gap: "30px",
                height: "350px",
                "@media (max-width: 800px)": { padding: "10px" },
              }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "22px", fontWeight: "600" }}
              >
                Flexibility in Placement
              </Typography>
              <Typography
                variant="p"
                sx={{
                  textAlign: "center",
                  lineHeight: "30px",
                  "@media (max-width: 1000px)": { lineHeight: "20px" },
                }}
              >
                Without the need to connect to the grid, off-grid solar systems
                offer greater flexibility in terms of their placement and
                location, making them a viable option for remote or
                hard-to-reach areas.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                bgcolor: "#dbdbdb",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "100px 70px",
                gap: "30px",
                height: "350px",
                "@media (max-width: 800px)": { padding: "10px" },
                "@media (max-width: 500px)": { bgcolor: "#f0df89" },
              }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "22px", fontWeight: "600" }}
              >
                Environmentally Friendly
              </Typography>
              <Typography
                variant="p"
                sx={{
                  textAlign: "center",
                  lineHeight: "30px",
                  "@media (max-width: 1000px)": { lineHeight: "20px" },
                }}
              >
                Off-grid solar systems are a clean, renewable energy source that
                have minimal environmental impact, making them a sustainable
                choice for your energy needs.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                bgcolor: "#f0df89",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "100px 70px",
                gap: "30px",
                height: "350px",
                "@media (max-width: 800px)": { padding: "10px" },
                "@media (max-width: 500px)": { bgcolor: "#dbdbdb" },
              }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "22px", fontWeight: "600" }}
              >
                Seamless Integration
              </Typography>
              <Typography
                variant="p"
                sx={{
                  textAlign: "center",
                  lineHeight: "30px",
                  "@media (max-width: 1000px)": { lineHeight: "20px" },
                }}
              >
                On-grid solar systems are designed to easily integrate with your
                home or business's existing electrical infrastructure, making
                the installation process more straightforward.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default OffGrid;
